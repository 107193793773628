.page-container {
  height: 50px;
}

.page-button {
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}

.page-number {
  float: left;
}

#search {
  margin: 10px;
  width: 80%;
}

@media (min-width: 1200px) {
  .table-custom {
    display: inline-table;
  }
}

.table-custom {
  display: table;
  font-size: 14px;
  background-color: white !important;
}

.row-clickeable {
  cursor: pointer;
}

.search-header {
  background-color: #dc3545;
}

.search-header-button {
  color: #fff;
}

.search-header-button:focus {
  outline: none !important;
  box-shadow: none;
}

.search-header-button:hover {
  color: lightgray;
}

@media (max-width: 480px) {
  .mobile-hidden {
    display: none;
  }
}
