.btn-brand {
  min-height: 80px;
  min-width: 80px;
}

@media (max-width: 640px) {
  .btn-brand {
    min-height: 50px;
    min-width: 50px;
  }
}
