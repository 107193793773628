.details-error {
    font-size: 14px;
    color: red;
    margin-left: 20px;
}

.btn-complete {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (min-width: 1200px) {
    .btn-complete {
        margin-left: 15px;
    }
}

.icon-check {
    font-size: 0.9rem;
    margin-left: 5px;
}