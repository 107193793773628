.table .media .media-body > span {
    color: #999;
}

.table .media .media-body span strong {
    color: #777;
}

.btn-width-100 {
    width: 100%;
    padding: 0;
}

.media-custom {
    border: 2px solid darkgrey;
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.media-body-custom {
    border-left: 2px solid darkgrey;
    background-color: white;
    min-height: 125px;
}

.media-left-custom {
    max-width: 100px;
}

.card-header-history {
    padding: 0.5em;
}

.table-custom-history {
    background-color: lightgrey;
    margin: 0;
}

.card-custom-history {
    margin-bottom: 5px;
}

#details {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 3px;
}

#details td, #details th {
    border: 1px solid #ddd;
    padding: 3px;
}

#details tr {
    white-space: nowrap;
}

#details th {
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: left;
    background-color: lightgrey;
}

.collapse-custom {
    overflow: scroll;
}

.comments-text {
    font-size: 13px;
    color: #7f7d7d;
    margin-bottom: 0;
}