.btn-buy-promo {
    height: 45px;
}

.custom-badge-promo {
    font-size: 30px;
}

.card-custom-promo {
    margin-top: 15px;
}

.promo-container {
    display: flex;
    flex: 0 50%;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-flex-promo {
    width: 45%;
    padding: 3px;
    margin: 8px;
}