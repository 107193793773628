.modal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modalContainer {
  width: 800px;
  min-height: 350px;
  background-color: #dee2e6;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(100, 111, 100, 0.3) 0px 7px 29px 0px;
  padding: 20px;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #112a49;
}

.btnClose:hover {
  background-color: darkred;
  color: whitesmoke;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 490px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.btn-close {
  background-color: #112a49;
  color: #ffffff;
}
