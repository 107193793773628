.form-news {
  min-width: 800px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.padding-middle {
  padding: 20px;
}

.acceptance-text {
  color: #7f7d7d;
  font-size: 13px;
}

.error-signup-general {
  color: red;
  font-size: 12px;
}

.error-list-general {
  margin-top: 10px;
}

.success-signup {
  margin-top: 15px;
}
