.form-news {
  min-width: 800px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.error-signup-general {
  color: red;
  font-size: 12px;
}

.error-list-general {
  margin-top: 10px;
}

.success-signup {
  margin-top: 15px;
}

.date-picker {
  max-width: 250px;
}

.observations {
  margin-top: 40px;
  max-width: 1140px;
}

.btn-save {
  max-width: 1140px;
}

.form-width {
  max-width: 1140px;
}
