@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
* {
  box-sizing: border-box;
}
body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  max-width: 500px;
  min-width: 320px;
  margin: auto;
  margin-top: 100px;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.user-info {
  margin-top: 0;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.padding-middle {
    padding: 20px;
}

.error-login {
    color: red;
    padding: 10px;
    font-size: 12px;
}
.badge-cart {
  bottom: 7px;
  left: 0 !important;
}

.fixed-right {
  position: absolute;
  right: 20px;
}

@media (max-width: 480px) {
  .fixed-right {
    position: relative;
    right: 0;
  }
}

.padding-middle {
  padding: 20px;
}

.acceptance-text {
  color: #7f7d7d;
  font-size: 13px;
}

.error-signup-general {
  color: red;
  font-size: 12px;
}

.error-list-general {
  margin-top: 10px;
}

.success-signup {
  margin-top: 15px;
}

.captcha-container {
  display: flex;
  margin: 15px;
  margin-left: 50px;
  align-content: center;
}

.page-container {
  height: 50px;
}

.page-button {
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}

.page-number {
  float: left;
}

#search {
  margin: 10px;
  width: 80%;
}

@media (min-width: 1200px) {
  .table-custom {
    display: inline-table;
  }
}

.table-custom {
  display: table;
  font-size: 14px;
  background-color: white !important;
}

.row-clickeable {
  cursor: pointer;
}

.search-header {
  background-color: #dc3545;
}

.search-header-button {
  color: #fff;
}

.search-header-button:focus {
  outline: none !important;
  box-shadow: none;
}

.search-header-button:hover {
  color: lightgray;
}

@media (max-width: 480px) {
  .mobile-hidden {
    display: none;
  }
}

.btn-brand {
  min-height: 80px;
  min-width: 80px;
}

@media (max-width: 640px) {
  .btn-brand {
    min-height: 50px;
    min-width: 50px;
  }
}

/* The search field */
#searchInput {
  box-sizing: border-box;
  font-size: 16px;
  padding: 6px;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.btn-search-custom {
  min-width: 120px;
  padding-left: 0;
  background-color: #e9ecef;
  color: #495057;
}

.icon-down {
  margin-left: 10px;
  font-size: 24px;
  position: absolute;
}

.icon-delete {
  font-size: 16px;
}

/* The search field when it gets focus/clicked on */
#searchInput:focus {
  outline: 1px solid #ddd;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  margin-top: 10px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #e9ecef;
  width: 120px;
  border: 1px solid #ddd;
  z-index: 100;
  max-height: 300px;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.dropdown-content div {
  color: black;
  padding: 3px;
  display: block;
  margin-bottom: 0;
  cursor: pointer;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.search-input {
  border-color: #6c757d;
}

.page {
    margin-top: 85px;
}

.badge-margin {
    margin-left: 10px;
}

.btn-cart {
    width: 100%;
}

.icon-cart {
    margin-right: 10px;
    font-size: 0.9rem;
}

.instructions-text {
    font-size: 14px;
    color: #7f7d7d;
    margin-left: 20px;
}

.aclaration-text {
    font-size: 13px;
    color: #7f7d7d;
}

.subtitle-text {
    font-size: 14px;
    color: black;
}

.price-text {
    font-size: 13px;
    color: black;
}

#main-menu {
    margin-top: 80px;
}

.menu-container {
    display: flex;
    flex: 0 1 50%;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-flex {
    width: 45%;
    padding: 3px;
    margin: 8px;
}

.welcome-text {
    text-align: center;
}

@media (min-width: 1200px) {
    .card-item-custom {
        width: 18%;
    }
}

.card-body {
    padding: 1em;
}

.icon-big {
    text-align: center;
    font-size: 80px;
    margin: 10px;
}

.btn-menu-item {
    width: 100%;
}

.item-title-badge {
    position: relative;
    left: 5px;
    font-weight: 600;
    font-family: 'Fira Sans', sans-serif;
}

.center-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 360px;
}
.btn-100 {
  margin: 5px;
}

.error-cart {
  color: red;
  text-align: center;
  font-size: 14px;
}

.icon-md-18 {
    font-size: 18px;
}
.details-error {
    font-size: 14px;
    color: red;
    margin-left: 20px;
}

.btn-complete {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (min-width: 1200px) {
    .btn-complete {
        margin-left: 15px;
    }
}

.icon-check {
    font-size: 0.9rem;
    margin-left: 5px;
}
.bg-gradient {
    background: #C9D6FF;
    background: linear-gradient(to right, #E2E2E2, #C9D6FF);
}

.margin-li {
    margin-bottom:1.2rem;
}

.pricing-divider {
    border-radius: 20px;
    background: #C64545;
    padding: 1em 0 4em;
    position: relative;
}
.blue .pricing-divider{
    background: #2D5772;
}
.green .pricing-divider {
    background: #1AA85C;
    padding-left: 5px;
    padding-right: 5px;
}

.pricing-divider-img {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 80px;
}
.deco-layer {
    transition: transform 0.5s;
}

.img-float {
    width:50px; position:absolute;top:-3.5rem;right:1rem
}

.princing-item {
    transition: all 150ms ease-out;
    margin-top: 25px;
}
.princing-item:hover {
    transform: scale(1.05);
}
.princing-item:hover .deco-layer--1 {
    transform: translate3d(15px, 0, 0);
}
.princing-item:hover .deco-layer--2 {
    transform: translate3d(-15px, 0, 0);
}



.table .media .media-body > span {
    color: #999;
}

.table .media .media-body span strong {
    color: #777;
}

.btn-width-100 {
    width: 100%;
    padding: 0;
}

.media-custom {
    border: 2px solid darkgrey;
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.media-body-custom {
    border-left: 2px solid darkgrey;
    background-color: white;
    min-height: 125px;
}

.media-left-custom {
    max-width: 100px;
}

.card-header-history {
    padding: 0.5em;
}

.table-custom-history {
    background-color: lightgrey;
    margin: 0;
}

.card-custom-history {
    margin-bottom: 5px;
}

#details {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 3px;
}

#details td, #details th {
    border: 1px solid #ddd;
    padding: 3px;
}

#details tr {
    white-space: nowrap;
}

#details th {
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: left;
    background-color: lightgrey;
}

.collapse-custom {
    overflow: scroll;
}

.comments-text {
    font-size: 13px;
    color: #7f7d7d;
    margin-bottom: 0;
}
.btn-buy-promo {
    height: 45px;
}

.custom-badge-promo {
    font-size: 30px;
}

.card-custom-promo {
    margin-top: 15px;
}

.promo-container {
    display: flex;
    flex: 0 1 50%;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-flex-promo {
    width: 45%;
    padding: 3px;
    margin: 8px;
}
.label-inputs {
  width: 100%;
}

.input-group-users {
  padding-left: 10px;
}

.icon-buttons-user {
  font-size: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.label-sliders {
  margin-left: 10px;
  margin-right: 10px;
}

.collapsible-icon {
    font-size: 2rem;
    position: absolute;
    right: 20px;
}
.modal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modalContainer {
  width: 800px;
  min-height: 350px;
  background-color: #dee2e6;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(100, 111, 100, 0.3) 0px 7px 29px 0px;
  padding: 20px;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #112a49;
}

.btnClose:hover {
  background-color: darkred;
  color: whitesmoke;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 490px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.btn-close {
  background-color: #112a49;
  color: #ffffff;
}

@media print {
  ::-webkit-scrollbar {
    display: none;
  }
  #articles-modal {
    display: auto;
    height: 100vh;
  }

  #articles-table {
    display: auto;
    font-size: 8px;
    overflow: visible !important;
  }
  .modal-body {
    max-height: 90vh;
    overflow: visible !important;
  }

  .modal-content {
    overflow: visible !important;
  }

  #btn-print {
    display: none;
  }
}

.total-articles {
  width: 100%;
}
.news-container {
  display: flex;
  flex: 0 1 50%;
  flex-direction: row;
  flex-wrap: wrap;
}

.card-news {
  min-height: 700px;
}

.card-flex-news {
  width: 45%;
  padding: 3px;
  margin: 8px;
}

.badge-news {
  margin-bottom: 15px;
}

.card-text-news {
  white-space: break-spaces;
  word-break: break-word;
}

.form-news {
  min-width: 800px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.padding-middle {
  padding: 20px;
}

.acceptance-text {
  color: #7f7d7d;
  font-size: 13px;
}

.error-signup-general {
  color: red;
  font-size: 12px;
}

.error-list-general {
  margin-top: 10px;
}

.success-signup {
  margin-top: 15px;
}

.form-news {
  min-width: 800px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.error-signup-general {
  color: red;
  font-size: 12px;
}

.error-list-general {
  margin-top: 10px;
}

.success-signup {
  margin-top: 15px;
}

.date-picker {
  max-width: 250px;
}

.observations {
  margin-top: 40px;
  max-width: 1140px;
}

.btn-save {
  max-width: 1140px;
}

.form-width {
  max-width: 1140px;
}

.products-section {
  max-width: 1140px;
  border: 3px solid darkgray;
  padding: 15px;
  border-radius: 8px;
}

.receipt-data {
  display: grid;
  grid-template-columns: 250px 300px 250px 250px;
  grid-gap: 10px;
  height: 95px;
}

.receipts-table {
  margin-top: 30px;
}

.card-data {
  display: grid;
  grid-template-columns: 250px 300px 250px 250px;
  grid-gap: 10px;
  height: 90px;
}

.btn-add-receipts {
  margin-top: 32px;
  max-width: 300px;
}

.dropdown-menu.show {
  display: block;
  z-index: 10000;
  max-height: 300px;
  overflow: scroll;
}

