/* The search field */
#searchInput {
  box-sizing: border-box;
  font-size: 16px;
  padding: 6px;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.btn-search-custom {
  min-width: 120px;
  padding-left: 0;
  background-color: #e9ecef;
  color: #495057;
}

.icon-down {
  margin-left: 10px;
  font-size: 24px;
  position: absolute;
}

.icon-delete {
  font-size: 16px;
}

/* The search field when it gets focus/clicked on */
#searchInput:focus {
  outline: 1px solid #ddd;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  margin-top: 10px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #e9ecef;
  width: 120px;
  border: 1px solid #ddd;
  z-index: 100;
  max-height: 300px;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.dropdown-content div {
  color: black;
  padding: 3px;
  display: block;
  margin-bottom: 0;
  cursor: pointer;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.search-input {
  border-color: #6c757d;
}
