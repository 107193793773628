.bg-gradient {
    background: #C9D6FF;
    background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);
    background: linear-gradient(to right, #E2E2E2, #C9D6FF);
}

.margin-li {
    margin-bottom:1.2rem;
}

.pricing-divider {
    border-radius: 20px;
    background: #C64545;
    padding: 1em 0 4em;
    position: relative;
}
.blue .pricing-divider{
    background: #2D5772;
}
.green .pricing-divider {
    background: #1AA85C;
    padding-left: 5px;
    padding-right: 5px;
}

.pricing-divider-img {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 80px;
}
.deco-layer {
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
}

.img-float {
    width:50px; position:absolute;top:-3.5rem;right:1rem
}

.princing-item {
    transition: all 150ms ease-out;
    margin-top: 25px;
}
.princing-item:hover {
    transform: scale(1.05);
}
.princing-item:hover .deco-layer--1 {
    -webkit-transform: translate3d(15px, 0, 0);
    transform: translate3d(15px, 0, 0);
}
.princing-item:hover .deco-layer--2 {
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0);
}


