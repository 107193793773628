.news-container {
  display: flex;
  flex: 0 50%;
  flex-direction: row;
  flex-wrap: wrap;
}

.card-news {
  min-height: 700px;
}

.card-flex-news {
  width: 45%;
  padding: 3px;
  margin: 8px;
}

.badge-news {
  margin-bottom: 15px;
}

.card-text-news {
  white-space: break-spaces;
  word-break: break-word;
}
