.badge-cart {
  bottom: 7px;
  left: 0 !important;
}

.fixed-right {
  position: absolute;
  right: 20px;
}

@media (max-width: 480px) {
  .fixed-right {
    position: relative;
    right: 0;
  }
}
