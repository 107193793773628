.page {
    margin-top: 85px;
}

.badge-margin {
    margin-left: 10px;
}

.btn-cart {
    width: 100%;
}

.icon-cart {
    margin-right: 10px;
    font-size: 0.9rem;
}

.instructions-text {
    font-size: 14px;
    color: #7f7d7d;
    margin-left: 20px;
}

.aclaration-text {
    font-size: 13px;
    color: #7f7d7d;
}

.subtitle-text {
    font-size: 14px;
    color: black;
}

.price-text {
    font-size: 13px;
    color: black;
}
