.products-section {
  max-width: 1140px;
  border: 3px solid darkgray;
  padding: 15px;
  border-radius: 8px;
}

.receipt-data {
  display: grid;
  grid-template-columns: 250px 300px 250px 250px;
  grid-gap: 10px;
  height: 95px;
}

.receipts-table {
  margin-top: 30px;
}

.card-data {
  display: grid;
  grid-template-columns: 250px 300px 250px 250px;
  grid-gap: 10px;
  height: 90px;
}

.btn-add-receipts {
  margin-top: 32px;
  max-width: 300px;
}

.dropdown-menu.show {
  display: block;
  z-index: 10000;
  max-height: 300px;
  overflow: scroll;
}
