@media print {
  ::-webkit-scrollbar {
    display: none;
  }
  #articles-modal {
    display: auto;
    height: 100vh;
  }

  #articles-table {
    display: auto;
    font-size: 8px;
    overflow: visible !important;
  }
  .modal-body {
    max-height: 90vh;
    overflow: visible !important;
  }

  .modal-content {
    overflow: visible !important;
  }

  #btn-print {
    display: none;
  }
}

.total-articles {
  width: 100%;
}