.label-inputs {
  width: 100%;
}

.input-group-users {
  padding-left: 10px;
}

.icon-buttons-user {
  font-size: 16px;
}
